import type { NextPage } from 'next'
import Head from 'next/head'

const SignUp: NextPage = () => {
  return (
    <>
      <Head>
        <title>Page not found</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <div className="flex items-center justify-center h-screen">
        <p>Page not found</p>
      </div>
    </>
  )
}

export default SignUp
